import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { Switch, BrowserRouter as Router, Route, Link } from "react-router-dom";
import routes from "./routes.js";
import Header from "./components/menu.jsx";
import "./styles/auth.css";
import "./App.css";
import protectedRoutes from "./protectedRoutes";
import * as firebase from "firebase";
import firebaseConfig from "./firebase.config";

import ProtectedRouteHoc from "./ProtectedRouteHoc";

firebase.initializeApp(firebaseConfig);

export const storage = firebase.storage().ref();

export const AuthContext = React.createContext(null);

function App() {
  const [isLoggedIn, setLoggedIn] = useState(false);

  function readSession() {
    const user = window.sessionStorage.getItem(
      `firebase:authUser:${firebaseConfig.apiKey}:[DEFAULT]`
    );
    if (user) setLoggedIn(true);
  }
  useEffect(() => {
    readSession();
  }, []);

  return (
    <AuthContext.Provider value={{ isLoggedIn, setLoggedIn }}>
      {/*Is logged in? {JSON.stringify(isLoggedIn)}*/}
      <div className="Wrapper">
        <Router>
          <Header isLoggedIn={isLoggedIn} />
          <div className="App">
            <h2 className="stray-cat">
              <Link to="/">Stray Cat</Link>
            </h2>
            <Switch>
              {protectedRoutes.map(route => (
                <ProtectedRouteHoc
                  key={route.path}
                  isLoggedIn={isLoggedIn}
                  path={route.path}
                  component={route.main}
                  exact={route.exact}
                  public={route.public}
                />
              ))}
              {routes.map(route => (
                <Route
                  key={route.path}
                  path={route.path}
                  exact={route.exact}
                  component={route.main}
                />
              ))}
            </Switch>
          </div>
        </Router>
      </div>
    </AuthContext.Provider>
  );
}

const rootElement = document.getElementById("root");
ReactDOM.render(<App />, rootElement);
