import React from "react";
import Login from "./Login";
import Join from "./Join";
import Contact from "./components/form.jsx";
import PortfolioList from "./components/portfolio.jsx";

const routes = [
  { name: "Home", path: "/", exact: true, main: () => <Contact /> },
  /*{ name: "Join", path: "/join", exact: true, main: () => <Join /> },*/
  {
    name: "Portfolio",
    path: "/portfolio",
    exact: true,
    main: () => <PortfolioList />
  },
  { name: "Login", path: "/login", exact: true, main: () => <Login /> }
];

export default routes;
