import React, { Component } from "react";
import axios from "axios";
import { Button, Form, FormGroup, Input } from "reactstrap";

class PortfolioForm extends Component {
  state = {
    name: "",
    url: "",
    img: "",
    sent: false,
    buttonText: "Skapa"
  };

  render() {
    return (
      <div className="container">
        <div className="col-md-4 justify-content-center offset-md-4">
          <h2>Lägg till ny sida</h2>
          <Form className="contact-form" onSubmit={e => this.formSubmit(e)}>
            <FormGroup>
              {/* <Label class="message-name" htmlFor="message-name">
            Your Name
    </Label> */}
              <Input
                onChange={e => this.setState({ name: e.target.value })}
                name="name"
                className="portfolio-name"
                type="text"
                placeholder="Namn"
                value={this.state.name}
              />
            </FormGroup>
            <FormGroup>
              {/* <Label class="message-email" htmlFor="message-email">
            Your Email
  </Label>*/}
              <Input
                onChange={e => this.setState({ url: e.target.value })}
                name="url"
                className="portfolio-url"
                type="text"
                placeholder="www.web.com"
                required
                value={this.state.url}
              />
            </FormGroup>
            <FormGroup>
              {/* <Label class="message" htmlFor="message-input">
            Your Message
</Label>*/}
              <Input
                type="text"
                onChange={e => this.setState({ img: e.target.value })}
                name="img"
                className="img-input"
                placeholder="Image link"
                value={this.state.img}
                required
              />
            </FormGroup>
            <div className="button--container">
              <Button type="submit" className="button button-primary">
                {this.state.buttonText}
              </Button>
            </div>
          </Form>
        </div>
      </div>
    );
  }

  formSubmit = e => {
    e.preventDefault();

    this.setState({
      buttonText: "...Skapar"
    });

    let data = {
      name: this.state.name,
      url: this.state.url,
      img: this.state.img
    };

    axios
      .post("https://scc-api-portfolio.now.sh/", data)
      .then(res => {
        this.setState({ sent: true }, this.resetForm());
      })
      .catch(() => {
        console.log("Portfolio skapades inte");
      });
  };

  resetForm = () => {
    this.setState({
      name: "",
      url: "",
      img: "",
      buttonText: "Ny portfolio skapades"
    });
  };
}

export default PortfolioForm;
