import React, { Component } from "react";
import * as firebase from "firebase";
import config from "../firebase.config";
import { storage } from "../index";
import axios from "axios";

const Portfolio = props => (
  <div className="col-md-4 justify-content-center">
    <a href={props.portfolio.url}>
      <img className="portfolioLogo" src={props.portfolio.img} />
    </a>
    {/*<td>
      <Link to={"/edit/" + props.todo._id}>Edit</Link>
    </td>*/}
  </div>
);

class PortfolioList extends Component {
  constructor(props) {
    super(props);
    this.state = { portfolios: [] };
  }

  getImage(img) {
    let { state } = this;
    storage
      .child(`${img}.png`)
      .getDownloadURL()
      .then(url => {
        state[img] = url;
        this.setState(state);
      })
      .catch(error => {
        // Handle any errors
      });
  }

  componentDidMount() {
    axios
      .get("https://scc-api-portfolio.now.sh/")
      .then(response => {
        this.setState({ portfolios: response.data });
      })
      .catch(function(error) {
        console.log(error);
      });
  }

  portfolioList() {
    return this.state.portfolios.map(function(currentPortfolio, i) {
      return <Portfolio portfolio={currentPortfolio} key={i} />;
    });
  }

  render() {
    return (
      <div id="portfolioRow" className="container">
        <h2>Portfolio</h2>
        <div className="row no-gutters">{this.portfolioList()}</div>
      </div>
    );
  }
}

export default PortfolioList;
