import React from "react";
import AdminView from "./AdminView";

const protectedRoutes = [
  {
    name: "admin",
    exact: true,
    path: "/admin",
    main: props => <AdminView {...props} />,
    public: false
  }
];

export default protectedRoutes;
