import React, { Component } from "react";
import axios from "axios";
import { Button, Form, FormGroup, Input } from "reactstrap";

class Contact extends Component {
  state = {
    name: "",
    message: "",
    email: "",
    sent: false,
    buttonText: "Skicka meddelande"
  };

  render() {
    return (
      <div className="container">
        <div className="col-md-4 justify-content-center offset-md-4">
          <h2>Contact</h2>
          <Form className="contact-form" onSubmit={e => this.formSubmit(e)}>
            <FormGroup>
              {/* <Label class="message-name" htmlFor="message-name">
            Your Name
    </Label> */}
              <Input
                onChange={e => this.setState({ name: e.target.value })}
                name="name"
                className="message-name"
                type="text"
                placeholder="Ditt namn"
                value={this.state.name}
              />
            </FormGroup>
            <FormGroup>
              {/* <Label class="message-email" htmlFor="message-email">
            Your Email
  </Label>*/}
              <Input
                onChange={e => this.setState({ email: e.target.value })}
                name="email"
                className="message-email"
                type="email"
                placeholder="your@email.com"
                required
                value={this.state.email}
              />
            </FormGroup>
            <FormGroup>
              {/* <Label class="message" htmlFor="message-input">
            Your Message
</Label>*/}
              <Input
                type="textarea"
                onChange={e => this.setState({ message: e.target.value })}
                name="message"
                className="message-input"
                placeholder="Skriv något..."
                value={this.state.message}
                required
              />
            </FormGroup>
            <div className="button--container">
              <Button type="submit" className="button button-primary">
                {this.state.buttonText}
              </Button>
            </div>
          </Form>
        </div>
      </div>
    );
  }

  formSubmit = e => {
    e.preventDefault();

    this.setState({
      buttonText: "...skickar"
    });

    let data = {
      name: this.state.name,
      email: this.state.email,
      message: this.state.message
    };

    axios
      .post("https://scc-api-contact.now.sh/", data)
      .then(res => {
        this.setState({ sent: true }, this.resetForm());
      })
      .catch(() => {
        console.log("Meddelandet skickades inte");
      });
  };

  resetForm = () => {
    this.setState({
      name: "",
      message: "",
      email: "",
      buttonText: "Meddelandet skickades"
    });
  };
}

export default Contact;
